import { isActiveFilter } from '../../../../../utils/filterUtils';
import { transformHourToDays } from '../../../gantt/gantt.helper';
const _Trans = transformHourToDays;
if (typeof window !== 'undefined') {
  window._Trans = _Trans;
}
const setGeneralFilter = (showedFilters, newGantt, updateRedux) => {
  if (!showedFilters) return;
  if (showedFilters.length === 0) return;
  const gantt = newGantt;

  gantt.isDataFiltered = true;

  let query = '';
  const connector = ' && ';
  let activeFilter = false;
  showedFilters.map((filter, index) => {
    const isLast = index == showedFilters.length - 1;
    let value = "'" + filter.filter_by + "'";
    activeFilter = isActiveFilter(filter);

    if (filter.filter_by === 100) {
      value = "'" + filter.filter_by + "'";
    } else if (
      filter.filter_by <= '100' &&
      filter.filter_by >= '99.9999999999'
    ) {
      value = "'99'";
    }

    let valueToFilter = 'el.' + filter.name;

    if (filter.data_type == 'date') {
      if (value) {
        switch (filter.equals) {
          case 'afterthan':
            query =
              query +
              '(new Date(' +
              valueToFilter +
              ')).getTime() > (new Date(' +
              value +
              ')).getTime()';
            break;
          case 'beforethan':
            query =
              query +
              '(new Date(' +
              valueToFilter +
              ')).getTime() < (new Date(' +
              value +
              ')).getTime()';
            break;
        }
      }
    } else if (filter.data_type == 'string') {
      switch (filter.equals) {
        case 'includes':
          query =
            String(query) +
            valueToFilter +
            '.toLocaleLowerCase().includes(' +
            value.toLocaleLowerCase() +
            ')';
          break;
        case 'notincludes':
          query =
            query +
            '!' +
            valueToFilter +
            '.toLocaleLowerCase().includes(' +
            value.toLocaleLowerCase() +
            ')';
          break;
        case 'is':
          query = String(query) + valueToFilter + ' == ' + value;
          break;
        case 'isnot':
          query = String(query) + valueToFilter + ' != ' + value;
          break;
      }
    } else if (filter.data_type == 'number') {
      if (
        filter.name == 'duration' ||
        filter.name == 'totalSlack' ||
        filter.name == 'freeSlack'
      ) {
        valueToFilter = 'window._Trans(el.' + filter.name + ')';
      }

      switch (filter.equals) {
        case 'equals':
          query =
            String(query) + valueToFilter + ' == parseFloat(' + value + ')';
          break;
        case 'notequals':
          query =
            String(query) + valueToFilter + ' != parseFloat(' + value + ')';
          query =
            query + ' && ' + valueToFilter + ' != parseFloat(99.9999999999999)';
          break;
        case 'morethan':
          query =
            String(query) + valueToFilter + ' > parseFloat(' + value + ')';
          break;
        case 'lessthan':
          query =
            String(query) + valueToFilter + ' < parseFloat(' + value + ')';
          break;
        case 'moreequalsthan':
          query =
            String(query) + valueToFilter + ' >= parseFloat(' + value + ')';
          break;
        case 'lessequalsthan':
          query =
            String(query) + valueToFilter + ' <= parseFloat(' + value + ')';
          break;
      }
    } else if (filter.data_type == 'array/images') {
      const value =
        valueToFilter == 'el.user' ? `el.${filter.ref}` : valueToFilter;
      switch (filter.equals) {
        case 'issomeof':
          query =
            query +
            `(${value}.filter( u =>
                        ${JSON.stringify(filter.filter_by)}.includes(u.${
                          filter.el_to_extract_from
                        })).length)`;
          break;
        case 'isnotsomeof':
          query =
            query +
            `!(${value}.filter( u =>
                        ${JSON.stringify(filter.filter_by)}.includes(u.${
                          filter.el_to_extract_from
                        })).length)`;
          break;
      }
    } else if (filter.data_type != 'array/images') {
      let toAddQueryMixed = [];
      let mixQueries = true;
      if (filter.mixed) {
        let howManyMixDoesInclude = 0;
        if (filter.filter_by.map) {
          filter.filter_by.map((by) => {
            if (filter.mixed_from.includes(by)) {
              howManyMixDoesInclude++;
              if (filter.equals == 'issomeof') {
                toAddQueryMixed.push(' el.' + by);
              } else if (filter.equals == 'isnotsomeof') {
                toAddQueryMixed.push(' !el.' + by);
              }
            }
          });
          toAddQueryMixed = toAddQueryMixed.join(' && ');

          if (filter.filter_by.length == howManyMixDoesInclude) {
            mixQueries = false;
          }
        }
      }

      switch (filter.equals) {
        case 'issomeof':
          if (filter.name == 'calendar_id') {
            query =
              query +
              value +
              '.split(",").includes(JSON.stringify(' +
              valueToFilter +
              '))';
          } else if (mixQueries) {
            query =
              query +
              value +
              '.split(",").map(elem => isNaN(elem)? elem : parseInt(elem)).includes(' +
              valueToFilter +
              ')';
          }

          if (toAddQueryMixed.length) {
            query = query + (mixQueries ? ' && ' : '') + toAddQueryMixed;
          }

          break;
        case 'isnotsomeof':
          if (mixQueries) {
            query =
              query +
              '!' +
              value +
              '.split(",").map(elem => isNaN(elem)? elem : parseInt(elem)).includes(' +
              valueToFilter +
              ')';
          }

          if (toAddQueryMixed.length) {
            query = query + (mixQueries ? ' &&' : '') + toAddQueryMixed;
          }
          break;
      }
    }

    if (!isLast) {
      query = query + connector;
    }
  });
  const tasks = gantt.getTaskByTime();
  const showedActivities = [];
  if (activeFilter) {
    console.log('query', query);

    tasks.filter((el) => {
      if (eval(query)) {
        el.should_be_showed = true;
        showedActivities.push(el.id);
      } else {
        el.should_be_showed = false;
      }
    });
    showParents(showedActivities, gantt);
  } else {
    gantt.isDataFiltered = false;
    tasks.filter((el) => {
      el.should_be_showed = true;
    });
  }

  gantt.scrollTo(null, 0);
  updateRedux();
};

const showParents = (showedActivities, gantt) => {
  const parentToshow = [];
  showedActivities.map((showedId) => {
    getGrandParent(showedId, parentToshow, gantt);
  });
  const uniqueParents = [...new Set(parentToshow)];
  uniqueParents.map((parentId) => {
    let object;
    if (parentId != '0' && parentId != 0) {
      object = gantt.getTask(parentId);
      if (object) {
        object.should_be_showed = true;
      }
    }
  });
};

const getGrandParent = (childId, array, gantt) => {
  const parentId = gantt.getParent(childId);
  if (parentId) {
    array.push(parentId);
    getGrandParent(parentId, array, gantt);
  }
};

export default setGeneralFilter;
