/** React components  */
import React, { useState, useEffect, Fragment } from 'react';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

/** Import elements from library Antd */
import { Row, Col, Button, Spin } from 'antd';

import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';

/** Redux implementation */
import { useSelector, useDispatch } from 'react-redux';
import { calendarActions } from '../../../redux/actions/calendarActions';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

/** Services */
import { calendarService, sectorService } from '../../../services';

/** import helpers */
import { formatShift, add1Hour } from './calendar.helper';

import cloneDeep from 'lodash/cloneDeep';

/** import components */
import CalendarItem from '../../../components/Calendar/CalendarItem';
import CalendarForm from '../../../components/Calendar/CalendarForm';
import CalendarEdit from '../../../components/Calendar/CalendarEdit';
import WorkHourModal from '../../../components/WorkHourModal';
import { ToolbarItem } from '../../../components/ToolbarGroup/components/ToolbarItem';

/** import css */
import './calendar.css';
import { withTranslation } from 'react-i18next';
import colors from '../../../stylesheets/variables.scss';
import modalStyles from '../gantt/modals.module.scss';

import { ClockIcon, CalendarIcon } from '../../../icons';
import { Colors } from '../../../constants/colors.constants.js';

/** import analytics */
import { trackingEvent } from '../../../analytics/index';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';

function CalendarView(props) {
  /** traduction */
  const { t } = props;
  const { height, width } = useWindowDimensions();
  /** redux */
  const projectState = useSelector((state) => state.projectState);
  const calendarState = useSelector((state) => state.calendarState);
  const dispatch = useDispatch();

  const { permission } = props;
  /** use props */
  const { goToTop } = props;

  /** hooks */
  const [loadingCal, setLoadingCal] = useState(false); /** handle load */
  const [loading, setLoading] = useState(false); /** handle load */
  const [visibleForm, setVisibleForm] = useState(false);
  const [calendarsList, setCalendarsList] = useState();
  const [showWorkHourModal, setShowWorkHourModal] = useState(false);
  const [hoursWorkModal, setHoursWorkModal] = useState({
    visible: false,
    title: '',
    name: '',
    description: ''
  });
  /** items */
  const workHourItem = {
    icon: ClockIcon,
    command: 'SHOW_WORK_HOURS_MODAL',
    tooltipI18nKey: 'master_plan.hours_worktime_setting'
  };

  /** object by default for Calendar Form */
  const arrBool = cloneDeep([...Array(7).keys()].map((i) => false));
  const templatecalendar = {
    name: null,
    days: cloneDeep([...Array(7).keys()].map((i, index) => false)),
    shiftStart_ini: cloneDeep([...Array(7).keys()].map((i) => false)),
    shiftEnd_ini: cloneDeep([...Array(7).keys()].map((i) => false)),
    shiftStart_end: cloneDeep([...Array(7).keys()].map((i) => false)),
    shiftEnd_end: cloneDeep([...Array(7).keys()].map((i) => false)),
    exceptions: []
  };

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);
    // loadCalendars()

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);

      dispatch(calendarActions.setCalendar(null));
      dispatch(calendarActions.setCalendarForm(null));
    };
  }, []);

  useEffect(() => {
    loadCalendars();
  }, [projectState.sectorSelected]);

  useEffect(() => {
    window.Appcues.page();
  });

  useEffect(() => {
    setCalendarsList(calendarState.calendarsBySector);
  }, [calendarState.calendarsBySector]);

  /** services */
  const getCalendars = async (sectorId) => {
    const calendars = await calendarService.showBySector(sectorId);
    return calendars;
  };

  const loadCalendars = async () => {
    setLoadingCal(true);
    setCalendarsList(null);
    const sectorRes = await sectorService.showCalendar(
      projectState.sectorSelected
    );
    const sectorGantts = sectorRes.sector.gantts;
    const resp = await getCalendars(projectState.sectorSelected);
    if (resp) {
      /** filter by sector */
      let calendarsActive = resp.calendar.filter(
        (e) => e.sectorId === projectState.sectorSelected
      );
      calendarsActive = calendarsActive.map((cal) => {
        cal.workingDays = cal.shifts;
        return cal;
      });
      if (!calendarsActive.length && sectorGantts.length == 0) {
        props.history.push('/masterplan');
        return;
      }
      setCalendarsList(calendarsActive);
    }
    setLoadingCal(false);
  };

  /** logic component */
  const handleClickCreateCalendar = () => {
    handleCancelEdit();
    setVisibleForm(true);
    dispatch(calendarActions.setCalendarForm(templatecalendar));

    trackingEvent(
      'start_calendar_creation',
      {
        ...getBasicAmplitudEventProperties()
      },
      AMPLITUDE_SERVICE
    );
  };

  const getCalendar = async (id) => {
    const calendar = await calendarService.show(id);
    return calendar;
  };

  const updateStateCalendar = async (itemSelected) => {
    /** reset state calendar */
    handleCancelEdit();

    const workingDaysFormatted = itemSelected.workingDays
      .sort((a, b) => a.correlative_id - b.correlative_id)
      .map((shift) => {
        const startAndEndArray = shift.shift_string.split('-');
        shift.shift_ini = startAndEndArray[0]
          .split(',')
          .map((e) => (e === 'false' ? false : parseInt(e)));
        shift.shift_end = startAndEndArray[1]
          .split(',')
          .map((e) => (e === 'false' ? false : parseInt(e)));
        return shift;
      });

    const exceptionWorkingDaysFormatted = [];
    itemSelected.exceptions.map((ex) => {
      ex.workingDays = ex.shifts
        .sort((a, b) => a.correlative_id - b.correlative_id)
        .map((shift) => {
          const startAndEndArray = shift.shift_string.split('-');
          shift.shift_ini = startAndEndArray[0]
            .split(',')
            .map((e) => (e === 'false' ? false : parseInt(e)));
          shift.shift_end = startAndEndArray[1]
            .split(',')
            .map((e) => (e === 'false' ? false : parseInt(e)));
          return shift;
        });
      exceptionWorkingDaysFormatted.push(ex);
    });

    /** build form object from item selected */
    // const start = itemSelected.shift_start.split('-')
    // const end = itemSelected.shift_end.split('-')

    const templateCalendarForm = {
      name: itemSelected.name,
      days: itemSelected.working_days.split(',').map((e) => e === '1'),
      /* shiftStart_ini: start[0].split(',').map(e => e === 'false' ? false : parseInt(e)),
            shiftStart_end: start[1].split(',').map(e => e === 'false' ? false : parseInt(e)),
            shiftEnd_ini: end[0].split(',').map(e => e === 'false' ? false : parseInt(e)),
            shiftEnd_end: end[1].split(',').map(e => e === 'false' ? false : parseInt(e)), */
      workingDays: workingDaysFormatted,
      exceptions: exceptionWorkingDaysFormatted,
      is_default: itemSelected.is_default
    };

    dispatch(calendarActions.setCalendarForm(templateCalendarForm));
    dispatch(calendarActions.setCalendar(itemSelected));
  };

  const handleClickItem = async (e, item, isForce = false) => {
    if (item.id === calendarState.calendarSelected?.id && !isForce) {
      return;
    }

    setLoading(true);
    const calendar = await getCalendar(item.id);
    const res = calendar.calendar;
    res.workingDays = res.shifts;
    await updateStateCalendar(res);
    goToTop();
    setLoading(false);
  };

  const handleCancelEdit = (e) => {
    e && e.preventDefault();
    dispatch(calendarActions.setCalendar(null));
    dispatch(calendarActions.setCalendarForm(null));
    goToTop();
  };

  const sectorObject = projectState.allSectors.find(
    (e) => e.id == projectState.sectorSelected
  );

  /** This effect keeps the hours working state updated with the sector selector changes */
  useEffect(() => {
    if (projectState.allSectors.length) {
      const sector = projectState.allSectors.find(
        (e) => e.id == projectState.sectorSelected
      );
      if (sector) {
        setHoursWorkModal({
          ...hoursWorkModal,
          hoursPerDay: sector.hoursPerDay,
          hoursPerWeek: sector.hoursPerWeek
        });
      }
    }
  }, [projectState.sectorSelected]);

  const handleClick = () => {
    setShowWorkHourModal(true);
    setHoursWorkModal({
      ...hoursWorkModal,
      visible: true,
      title: t('master_plan.hours_worktime_setting')
    });
  };

  const handleCloseModal = () => {
    setShowWorkHourModal(false);
  };

  return (
    <Fragment>
      <div
        className="wrappedCalendars"
        style={{ minHeight: height - 128, maxHeight: height - 128 }}>
        <Row
          className="viewCalendars"
          style={{ minHeight: height - 128, maxHeight: height - 128 }}>
          <Col
            span={12}
            className="wrapp"
            style={{
              overflow: 'auto',
              minHeight: height - 128,
              maxHeight: height - 128
            }}>
            <Row className="calendarsHeader">
              <Col span={12}>
                <div className="title">
                  {t('master_plan_calendars.created_calendars')}
                </div>
              </Col>
              <Row span={12} className="actions">
                <ToolbarItem
                  onClick={() => handleClick()}
                  config={workHourItem}
                  onCommandDispatched={handleClick}
                  t={t}
                  ganttObject={props.ganttObject}>
                  <span>{t('master_plan.hours_worktime_setting')}</span>
                </ToolbarItem>
                {showWorkHourModal && (
                  <WorkHourModal
                    hoursWorkModal={hoursWorkModal}
                    setHoursWorkModal={setHoursWorkModal}
                    modalStyles={modalStyles}
                    t={t}
                    sectorObject={sectorObject}
                    colors={colors}
                    permission={permission}
                  />
                )}
                <Button
                  disabled={permission == 'V'}
                  type="primary"
                  shape="round"
                  className="btnPrimary"
                  onClick={() => handleClickCreateCalendar()}>
                  <CalendarIcon color={Colors.BLACK} className="calendarIcon" />
                  <span>{t('master_plan_calendars.create_calendar')}</span>
                </Button>
              </Row>
              <Col span={24} className="calendarsList">
                {loadingCal ? (
                  <Spin
                    indicator={<LoadingOutlined />}
                    className="spinCalendars"
                  />
                ) : (
                  calendarsList &&
                  calendarsList.map((item, index) => (
                    <div key={index} onClick={(e) => handleClickItem(e, item)}>
                      <CalendarItem
                        item={item}
                        handleClickItem={handleClickItem}
                        setCalendarsList={setCalendarsList}
                      />
                    </div>
                  ))
                )}
              </Col>
            </Row>
          </Col>
          <Col
            span={12}
            className="wrappEdit"
            style={{
              overflow: 'auto',
              minHeight: height - 128,
              maxHeight: height - 128
            }}>
            {loading ? (
              <Spin indicator={<LoadingOutlined />} className="spinCalendars" />
            ) : (
              <CalendarEdit
                permission={permission}
                handleCancelEdit={handleCancelEdit}
                formatShift={formatShift}
                add1Hour={add1Hour}
                loadCalendars={loadCalendars}
                calendarsList={calendarsList}
              />
            )}
          </Col>
        </Row>
      </div>

      {/* Component Form Calendar */}
      <CalendarForm
        t={t}
        visibleForm={visibleForm}
        setVisibleForm={setVisibleForm}
        formatShift={formatShift}
        add1Hour={add1Hour}
        calendarsList={calendarsList}
      />
    </Fragment>
  );
}

export default withTranslation()(CalendarView);
