import moment from 'moment';

const setRangeFilter = (range = {}, gantt, setIsRangeFiltered = null) => {
  return new Promise((resolve) => {
    if (!Object.keys(range).length) {
      return resolve([]);
    }

    if (range.start && range.end) {
      const dates = [range.start, range.end];
      const dateStrings = [];
      dateStrings[0] = moment(dates[0]).format('YYYY-MM-DD');
      dateStrings[1] = moment(dates[1]).format('YYYY-MM-DD');
      gantt.isDataFiltered = true;

      if (setIsRangeFiltered) {
        setIsRangeFiltered(dateStrings);
      }
      const tasks = gantt.getTaskByTime();
      const activitiesToShow = [];
      tasks.map((task) => {
        const start = new Date(dateStrings[0]).getTime(); /** startdate */
        const end = new Date(dateStrings[1]).getTime(); /** enddate */
        const taskStart = task.start_date.getTime(); /** startD */
        const taskEnd = task.end_date.getTime(); /** endD */
        if (
          (taskStart >= start && taskEnd <= end) ||
          (taskStart >= start && taskStart <= end) ||
          (start >= taskStart && start <= taskEnd)
        ) {
          activitiesToShow.push(task.id);
          task.should_be_showed = true;
        } else {
          task.should_be_showed = false;
        }
      });
      gantt.refreshData();
      return resolve(activitiesToShow);
    }
  });
};

export default setRangeFilter;
