import { sentryLogger } from '../../../../../monitor/monitor';
import { CONSTANTS } from '../constants';

const { FREQUENCY_TYPES, INVALID_SHIFT } = CONSTANTS;

const getMasterActivity = (gantt) => {
  const activityMaster = gantt.getTaskByIndex(0);
  if (!activityMaster) {
    sentryLogger.error('No activity master found');
    return null;
  }
  return activityMaster;
};

const extendDateRange = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  start.setFullYear(start.getFullYear() - 1);
  end.setFullYear(end.getFullYear() + 1);

  return { startMaster: start, endMaster: end };
};

const getExtendedDateRange = (gantt) => {
  const master = getMasterActivity(gantt);
  if (!master) return null;

  return extendDateRange(master.start_date, master.end_date);
};

const iterateHolidayDates = (currentDate, endDate, callback) => {
  let current = new Date(currentDate);
  do {
    current = callback(current);
  } while (current <= endDate);
};

const processHolidayWorkTime = ({ holiday, gantt, calendar, dateRange }) => {
  const { startMaster, endMaster } = dateRange;
  const holidayDates = parseHolidayDates(gantt, holiday);
  if (!holidayDates) return;

  const { holidayInit, holidayEnd } = holidayDates;

  iterateHolidayDates(holidayInit, holidayEnd, (currentDate) =>
    processHolidayRange({
      calendar,
      holiday,
      currentDate,
      startMaster,
      endMaster
    })
  );
};

const parseHolidayDates = (gantt, holiday) => {
  if (!holiday?.from_date || !holiday?.to_date) {
    sentryLogger.error('Invalid holiday dates', {
      fromDate: holiday?.from_date,
      toDate: holiday?.to_date,
      holiday
    });
    return null;
  }

  const holidayInit = gantt.date.parseDate(holiday.from_date, 'xml_date');
  const holidayEnd = gantt.date.parseDate(holiday.to_date, 'xml_date');

  return { holidayInit, holidayEnd };
};

const processHolidayRange = ({
  calendar,
  holiday,
  currentDate,
  startMaster,
  endMaster
}) => {
  configureWorkTime({
    calendar,
    holiday,
    currentDate,
    startMaster,
    endMaster
  });

  return updateCurrentHolidayDate(currentDate, holiday.every_type);
};

const validateFrequencyType = (everyType) => {
  if (!FREQUENCY_TYPES.includes(everyType)) {
    sentryLogger.warn(
      `Invalid recurrence type: ${everyType}, defaulting to DAILY`
    );
    return false;
  }
  return true;
};

const getDateUpdater = (everyType) => {
  const dateUpdaters = {
    YEARLY: (date) => date.setFullYear(date.getFullYear() + 1),
    DAILY: (date) => date.setDate(date.getDate() + 1),
    WEEKLY: (date) => date.setDate(date.getDate() + 7),
    MONTHLY: (date) => date.setMonth(date.getMonth() + 1)
  };
  return dateUpdaters[everyType] || dateUpdaters.DAILY;
};

const updateCurrentHolidayDate = (currentDate, everyType) => {
  validateFrequencyType(everyType);
  const updater = getDateUpdater(everyType);
  return new Date(updater(currentDate));
};

const configureWorkTime = ({
  calendar,
  holiday,
  currentDate,
  startMaster,
  endMaster
}) => {
  const hoursShift = calculateHoursShift(holiday);

  if (currentDate >= startMaster && currentDate <= endMaster) {
    calendar.setWorkTime({ date: currentDate, hours: hoursShift });
  }
};

const isValidHoliday = (holiday) => {
  if (!holiday?.workable) {
    return false;
  }

  if (!Array.isArray(holiday.shifts)) {
    sentryLogger.warn('Holiday shifts is not an array', { holiday });
    return false;
  }

  return true;
};

const sortShiftsByCorrelativeId = (shifts) =>
  [...shifts].sort((a, b) => {
    const aId = parseInt(a.correlative_id);
    const bId = parseInt(b.correlative_id);
    if (Number.isNaN(aId) || Number.isNaN(bId)) {
      sentryLogger.warn('Invalid correlative_id found', { a, b });
      return 0;
    }
    return aId - bId;
  });

const filterValidShifts = (shifts) =>
  shifts.reduce((acc, shift) => {
    if (shift.shift_string !== INVALID_SHIFT) {
      acc.push(shift.shift_string);
    }
    return acc;
  }, []);

const calculateHoursShift = (holiday) => {
  if (!isValidHoliday(holiday)) {
    return false;
  }

  const sortedShifts = sortShiftsByCorrelativeId(holiday.shifts);
  return filterValidShifts(sortedShifts);
};

const validateRequiredParams = (gantt, calendar) => {
  if (!gantt || !calendar) {
    sentryLogger.error('Missing required parameters', {
      hasGantt: !!gantt,
      hasCalendar: !!calendar
    });
    return false;
  }
  return true;
};

const validateHolidaysArray = (holidays) => {
  if (!Array.isArray(holidays)) {
    sentryLogger.error('Holidays parameter is not an array', {
      holidaysType: typeof holidays,
      holidaysValue: holidays
    });
    return false;
  }

  if (!holidays.length) {
    sentryLogger.warn('Holidays array is empty');
    return false;
  }

  return true;
};

export const setHolidaysForCalendar = (gantt, holidays, calendar) => {
  if (!validateRequiredParams(gantt, calendar)) return;
  if (!validateHolidaysArray(holidays)) return;

  const dateRange = getExtendedDateRange(gantt);
  if (!dateRange) return;

  holidays.forEach((holiday) =>
    processHolidayWorkTime({
      holiday,
      gantt,
      calendar,
      dateRange
    })
  );
};

export { validateRequiredParams };
