/* eslint-disable no-negated-condition */
/* eslint-disable no-eval */
/* eslint-disable prefer-const */
import {
  from_number_to_code,
  from_code_to_number
} from '../../../assets/js/custom_gantt_fields/custom_predecessor';
import {
  formatter,
  customFormatter
} from '../../../assets/js/field_config/formatter-gantt';

import { store } from '../../../redux/store';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import { baseCalendarService } from '../../../services/basecalendar.service';
import { procoreService } from '../../../services/procore.service';
import { baseworkingdayService, sectorService } from '../../../services';
import { BaseCalendarExceptionDaysService } from '../../../services/basecalendarexceptiondays.service';
import { dynamicSort } from '../../../utils';
import { gantt } from '../../../assets/gantt/dhtmlxgantt';
import { addBreadcrumbUtil, log } from '../../../monitor/monitor';
import { getSessionTokenData } from '../../../utils/userUtils';
import { validateActivityProperties } from './validateActivityBeforeSave';

const ZERO = 0;

/**
 * This object let through their attributes (which has same names that DHTMLX plugin) transforms to ProPlanner enum nomenclature for constraints
 */
export const GanttConstraint = {
  asap: 'As soon As Possible',
  alap: 'As late As Possible',
  snet: 'Start No Earlier Than',
  snlt: 'Start No Later Than',
  mso: 'Must Start On',
  mfo: 'Must Finish On',
  fnet: 'Finish No Earlier Than',
  fnlt: 'Finish No Later Than'
};

/**
 * This adapter function takes the ProPlanner DB enum value for activities constraints and transforms it to DHTMLX format
 * @param {*} largeName Large name enum from ProPlanner DB
 */
export const fromLargeToShortGanttConstraintName = (largeName) => {
  switch (largeName) {
    case GanttConstraint.asap:
      return 'asap';
    case GanttConstraint.alap:
      return 'alap';
    case GanttConstraint.snet:
      return 'snet';
    case GanttConstraint.snlt:
      return 'snlt';
    case GanttConstraint.mso:
      return 'mso';
    case GanttConstraint.mfo:
      return 'mfo';
    case GanttConstraint.fnlt:
      return 'fnlt';
    case GanttConstraint.fnet:
      return 'fnet';
  }
};

/**
 * This adapter function handles the hour feature integration, transforming hours from Gantt DHTMLX to days
 * @param {*} hours Hours integer format from gantt plugin
 */
export const transformHourToDays = (
  hours,
  customHoursPerDay = false,
  customHoursPerWeek = false
) => {
  const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
  let dayStringFormatDuration;
  if (customHoursPerDay && customHoursPerWeek) {
    dayStringFormatDuration = customFormatter(
      customHoursPerDay,
      customHoursPerWeek
    ).format(hours);
  } else {
    dayStringFormatDuration = formatter(currentSector).format(hours);
  }
  /** Hour integration: Duration from component comes in hours, but at BD must be interpreted as days */
  let durationNumberDaysForDB;
  if (dayStringFormatDuration.includes(' day')) {
    durationNumberDaysForDB = parseFloat(
      dayStringFormatDuration.split(' day')[0]
    );
  } else if (dayStringFormatDuration.includes(' days')) {
    durationNumberDaysForDB = parseFloat(
      dayStringFormatDuration.split(' days')[0]
    );
  }

  return durationNumberDaysForDB;
};

/**
 * This adapter function handles the hour feature integration, transforming days to hours integer as Gantt DHTMLX interprets
 * @param {*} days Days number to transform to hours
 */
export const transformDaysToHours = (days) => {
  const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
  const activityBDDuration = days + 'days';
  const hoursDuration = formatter(currentSector).parse(activityBDDuration);

  return hoursDuration;
};

/**
 * This adapter function transforms an link object from DHTMLX plugin to ProPlanner DB Object model
 * @param {*} ganttDhtmlxLink Link object from Gantt DHTMLX plugin
 */
export const convertLinkToActivityRelation = (ganttDhtmlxLink) => {
  const activityRelation = {
    source_id: parseInt(ganttDhtmlxLink.source),
    target_id: parseInt(ganttDhtmlxLink.target),
    /* lag: ganttDhtmlxLink.lag, */
    type: from_number_to_code(ganttDhtmlxLink.type),
    ganttId: ganttDhtmlxLink.ganttId,
    sectorId: ganttDhtmlxLink.sectorId,
    unique_id: ganttDhtmlxLink.id
  };

  /** Hours integration feature */
  activityRelation.lag = transformHourToDays(ganttDhtmlxLink.lag);

  return activityRelation;
};

/**
 * This adapter function transforms an activity relation from ProPlanner DB data model to Link object from Plugin DHTMLX
 * @param {*} activityrelation Activity Relation from ProPlanner DB
 */
export const convertActivityRelationToLink = (activityrelation) => {
  const link = {
    id: parseInt(activityrelation.unique_id),
    source: activityrelation.source_id,
    target: activityrelation.target_id,
    /* lag: activityrelation.lag, */
    type: from_code_to_number(activityrelation.type),
    ganttId: activityrelation.ganttId,
    sectorId: activityrelation.sectorId,
    proplannerId: activityrelation.id
  };

  /** Hours integration feature */
  link.lag = transformDaysToHours(activityrelation.lag);

  return link;
};

/**
 * This adapter function transforms an task object from DHTMLX plugin to ProPlanner activity format
 * @param {*} ganttDhtmlxTask Task object from DHTMLX gantt plugin
 */
export const convertGanttTaskToActivity = (ganttDhtmlxTask) => {
  const activity = {
    name: ganttDhtmlxTask.text,
    unique_id: ganttDhtmlxTask.id,
    parent_id: ganttDhtmlxTask.parent,
    description: ganttDhtmlxTask.description,
    cost: ganttDhtmlxTask.cost,
    progress: ganttDhtmlxTask.progress,
    constraint: GanttConstraint[ganttDhtmlxTask.constraint_type],
    start_date: ganttDhtmlxTask.start_date,
    end_date: ganttDhtmlxTask.end_date,
    constraint_date: ganttDhtmlxTask.constraint_date,
    type: ganttDhtmlxTask.type ? ganttDhtmlxTask.type : 'task',
    correlative_id: ganttDhtmlxTask.correlative_id,
    unique_correlative_id: ganttDhtmlxTask.unique_correlative_id,
    hhWorkTime: ganttDhtmlxTask.hhWorkTime,
    ponderator: ganttDhtmlxTask.ponderator,
    used_cost: ganttDhtmlxTask.used_cost,
    real_cost: ganttDhtmlxTask.real_cost,
    real_work: ganttDhtmlxTask.real_work,
    responsables: ganttDhtmlxTask.responsables,
    tags: ganttDhtmlxTask.tags,
    subcontractId: ganttDhtmlxTask.subcontractId,
    freeSlack: ganttDhtmlxTask.freeSlack,
    is_critical: ganttDhtmlxTask.is_critical === 'Si',
    hasNewActivities: Boolean(ganttDhtmlxTask.hasNewActivities),
    newActivitiesArray: ganttDhtmlxTask.newActivitiesArray
      ? JSON.stringify(ganttDhtmlxTask.newActivitiesArray)
      : '',
    isNewActivity: ganttDhtmlxTask.isNewActivity,
    calendarId: ganttDhtmlxTask.calendar_id,
    duration: transformHourToDays(ganttDhtmlxTask.duration),
    sumOfDurationRecursively: transformHourToDays(
      ganttDhtmlxTask.sumOfDurationRecursively
    )
  };

  const validatedActivity = validateActivityProperties(activity);
  return validatedActivity;
};

/**
 * This adapter function takes an Activity object from ProPlanner DB data model, and transforms it to an Task object from Gantt DTHMLX plugin
 * @param {*} activity Activity object from ProPlanner DB
 */
export const convertActivityToGanttTask = (activity) => {
  const ganttTask = {
    text: activity.name,
    id: parseInt(activity.unique_id),
    parent: activity.parent_id,
    description: activity.description || '',
    progress: activity.progress,
    constraint_type: fromLargeToShortGanttConstraintName(activity.constraint),
    constraint_date: activity.constraint_date
      ? new Date(activity.constraint_date)
      : null,
    start_date: new Date(activity.start_date),
    sectorId: activity.sectorId,
    ganttId: activity.ganttId,
    proplannerId: activity.id,
    type: activity.type,
    non_parsed_original_start_date: activity.start_date,
    non_parsed_original_end_date: activity.end_date,
    non_parsed_original_constraint_date: activity.constraint_date,
    should_correct_start_date: true,
    correlative_id: activity.correlative_id,
    unique_correlative_id: activity.unique_correlative_id,
    is_lookahead: activity.isOnLookahead,
    should_be_showed: true,
    baseline_points: activity.baseline_points,
    hhWorkTime: activity.hhWorkTime,
    cost: activity.cost,
    used_cost: activity.used_cost || 0,
    real_cost: activity.real_cost || 0,
    real_work: activity.real_work || 0,
    activityModifications: activity.activitymodifications,
    calendar_id: activity.calendarId,
    ponderator: activity.ponderator,
    tasks: activity.tasks,
    responsables: activity.responsables,
    tags: activity.tags,
    subcontractId: activity.subcontractId,
    is_critical: activity.is_critical ? 'Si' : 'No',
    non_parsed_freeslack: activity.freeSlack,
    non_parsed_ponderator: activity.ponderator,
    non_parsed_is_critical: activity.is_critical,
    hasNewActivities: activity.hasNewActivities,
    isNewActivity: activity.isNewActivity
  };
  if (
    /* activity.isOnLookahead || */ activity.progress != 0 &&
    activity.type != 'milestone'
  ) {
    ganttTask.auto_scheduling = false;
  }
  const sector = JSON.parse(sessionStorage.getItem('currentSector'));
  const message =
    'The activity with ID ' +
    activity.id +
    ' in the sector ID ' +
    sector.id +
    ' cant load the newActivitiesArray with value ' +
    activity.newActivitiesArray;

  try {
    if (
      activity.newActivitiesArray.length &&
      !activity.newActivitiesArray.includes('[')
    ) {
      const correctedArray = '[' + activity.newActivitiesArray + ']';
      const parsedArray = JSON.parse(correctedArray);
      ganttTask.newActivitiesArray = parsedArray;
      Sentry.captureMessage(message, 'warning');
    } else {
      ganttTask.newActivitiesArray = activity.newActivitiesArray
        ? JSON.parse(activity.newActivitiesArray)
        : [];
    }
  } catch (e) {
    ganttTask.newActivitiesArray = [];
    if (activity?.newActivitiesArray != null) {
      Sentry.captureMessage(message, 'warning');
    }
  }

  /** Hour integration: From DB duration value comes in Days, but at gantt component the value must be interpreted as days */
  const hoursDuration = transformDaysToHours(activity.duration);
  ganttTask.sumOfDurationRecursively = transformDaysToHours(
    activity.sumOfDurationRecursively
  );
  ganttTask.duration = hoursDuration;
  ganttTask.duration_milestone_bugged = hoursDuration;
  ganttTask.for_disable_milestone_duration = hoursDuration;
  ganttTask.non_parsed_original_duration = hoursDuration;

  if (ganttTask.constraint_type == 'asap') {
    ganttTask.real_constraint_type = ganttTask.constraint_type;
    // ganttTask.correct_constraint_bug = true
  } else {
    ganttTask.real_constraint_type = ganttTask.constraint_type;
  }

  if (
    activity.correlative_id == 0 &&
    activity.type == 'project' &&
    (ganttTask.parent != 0 || ganttTask.parent != '0')
  ) {
    ganttTask.parent = '0';
  }

  // if (ganttTask.type != 'project') {
  //     if (ganttTask.is_lookahead) {
  //         ganttTask.color = '#9b3939'
  //     }
  // }
  if (ganttTask.type != 'milestone') {
    ganttTask.color = '#00000000';
  }

  return ganttTask;
};

let calendar = [];

function getWorkingDays(calendars) {
  let calendario = [];
  calendars.forEach((calendar) => {
    let wd = [];
    let nonWorking = [];
    let working = [];
    calendar.working_days.forEach((wds, index) => {
      if (wds === 'WORKING') {
        wd.push(index);
      }
    });
    calendar.exceptions.forEach((exception) => {
      if (exception.working === false) {
        nonWorking.push(exception.init_date);
        nonWorking.push(exception.end_date);
      } else if (exception.working === true) {
        working.push(exception.init_date);
        working.push(exception.end_date);
      }
    });
    calendario.push({
      is_default: calendar.is_default,
      id: calendar.unique_id,
      working_days: wd,
      exceptions: calendar.exceptions,
      exceptions_non_working: nonWorking,
      exceptions_working: working
    });
  });
  calendar = calendario;
  return calendario;
}

function validateDates(start, end, calendar) {
  if (!calendar.working_days.includes(start.day())) {
    // alert("fecha inicio en un dia no laboral");
  }
  if (!calendar.working_days.includes(end.day())) {
    // alert("fecha termino en un dia no laboral");
  }
  if (calendar.exceptions_non_working.includes(start.format('YYYY-MM-DD'))) {
    // alert("inicia en un dia feriado");
  }
  if (calendar.exceptions_non_working.includes(end.format('YYYY-MM-DD'))) {
    // alert("termina en un dia feriado");
  }
}

export const calculateDuration = (start_at, end_at, calendars, id = null) => {
  if (calendar.length === 0) {
    calendars = getWorkingDays(calendars);
  } else {
    calendars = calendar;
  }
  let defaultCalendar = [];
  let start = moment(start_at);
  let end = moment(end_at);
  let invertir = false;
  // booleano utilizado para saber si se debe invertir el numero al termino de la operacion
  if (start > end) {
    invertir = true;
    let aux_end = end;
    end = start;
    start = aux_end;
  }
  if (id === null) {
    defaultCalendar = calendars.find((cal) => cal.is_default === true);
  } else {
    defaultCalendar = calendars.find((cal) => cal.id === id);
  }
  let dow = '';
  let dayCounter = 0;
  validateDates(start, end, defaultCalendar);
  while (start.isSameOrBefore(end)) {
    dow = start.day();
    if (
      defaultCalendar.exceptions_non_working.includes(
        start.format('YYYY-MM-DD')
      ) === false
    ) {
      if (
        defaultCalendar.working_days.includes(dow) ||
        defaultCalendar.exceptions_working.includes(start.format('YYYY-MM-DD'))
      ) {
        dayCounter += 1;
      }
    }
    start.add(1, 'd');
  }

  // para fechas donde el end es menor al start se retorna duracion negativa
  if (invertir === true) dayCounter = -Math.abs(dayCounter);
  return dayCounter;
};

export const getDurationRecursively = (value, activity, gantt) => {
  const childTasks = gantt.getChildren(activity.id);
  if (childTasks.length) {
    childTasks.map((child) => {
      const taskObject = gantt.getTask(child);

      /** prevent data without baseline_points */
      const activeBaseline = taskObject?.baseline_points?.find((base) => {
        if (base.sectorbaselineversion) {
          if (base.sectorbaselineversion.active) {
            return true;
          }
        }
      });
      const last_baseline = activeBaseline;
      if (last_baseline) {
        if (taskObject.type == 'project') {
          getDurationRecursively(value, taskObject, gantt);
        } else if (taskObject.type != 'milestone') {
          value.push(transformDaysToHours(last_baseline.duration));
        }
      }
    });
  }
};

/**
 * This function deals with auto ponderator feature from PP
 * @param {*} parent Parent to get auto ponderators
 */
export const calculatePonderators = (parent, gantt, projectState = null) => {
  if (parent && parent != '0') {
    const projectSelected = projectState.allProjects.find(
      (el) => el.id == projectState.projectSelected
    );
    /** Define the name of attr to extract from parent object */
    let childTasks = gantt.getChildren(parent.id);
    if (childTasks.length) {
      /**
       *  numerator   -> Number as 100 less ponderators with progress
       *  ---------
       *  denominator -> Sum of tasks ponderator criteria (hh/cost/duration)
       */
      let numerator = 100;
      let denominator = 0;
      childTasks.map((childId) => {
        let activity = gantt.getTask(childId);

        if (activity.baseline_points) {
          if (activity.baseline_points.length) {
            const activeBaseline = activity.baseline_points.find((base) => {
              if (base.sectorbaselineversion) {
                if (base.sectorbaselineversion.active) {
                  return true;
                }
              }
            });
            const last_baseline = activeBaseline;

            if (last_baseline) {
              let value = [];
              if (projectSelected.activity_creter.toUpperCase() == 'DURATION') {
                if (activity.type == 'project') {
                  getDurationRecursively(value, activity, gantt);
                  if (value.length) {
                    activity.sumOfDurationRecursively = value.reduce(
                      (x, y) => x + y
                    );
                    denominator += activity.sumOfDurationRecursively;
                  }
                } else if (activity.type != 'milestone') {
                  denominator += transformDaysToHours(last_baseline.duration);
                } else {
                  denominator += 0;
                }
              } else if (
                projectSelected.activity_creter.toUpperCase() == 'COST'
              ) {
                const toAdd =
                  typeof last_baseline.cost === 'string'
                    ? parseFloat(last_baseline.cost)
                    : last_baseline.cost;
                denominator += toAdd;
              } else if (
                projectSelected.activity_creter.toUpperCase() == 'HH'
              ) {
                const toAdd =
                  typeof last_baseline.hh_work === 'string'
                    ? parseFloat(last_baseline.hh_work)
                    : last_baseline.hh_work;
                denominator += toAdd;
              }
            }
          }
        }
      });

      /** This constant must be used to multiply each criteria (hh/duration/cost) whitout progress */
      let relationValue = numerator / denominator;
      if (!denominator) relationValue = 0;

      /**
       * Note: This section code below, allow to inline editing.
       * Just for now, this is working on calculating ponderators which includes follow actions:
       * - Add task
       * - Delete Task
       * - Change duration on a task
       * - Cambia la duracion de tareas hijas, de manera que la tarea padre cambia tabien su duracion
       */
      childTasks.map((childId) => {
        let activity = gantt.getTask(childId);

        if (activity.baseline_points) {
          if (activity.baseline_points.length) {
            const activeBaseline = activity.baseline_points.find((base) => {
              if (base.sectorbaselineversion) {
                if (base.sectorbaselineversion.active) {
                  return true;
                }
              }
            });
            const last_baseline = activeBaseline;

            if (last_baseline) {
              /* if (activity.progress == 0) { */
              let newPonderator;
              if (projectSelected.activity_creter.toUpperCase() == 'DURATION') {
                if (activity.type == 'project') {
                  newPonderator =
                    relationValue * activity.sumOfDurationRecursively;
                } else if (activity.type != 'milestone') {
                  newPonderator =
                    relationValue *
                    transformDaysToHours(last_baseline.duration);
                } else {
                  newPonderator = 0;
                }
              } else if (
                projectSelected.activity_creter.toUpperCase() == 'COST'
              ) {
                newPonderator = relationValue * last_baseline.cost;
              } else if (
                projectSelected.activity_creter.toUpperCase() == 'HH'
              ) {
                newPonderator = relationValue * last_baseline.hh_work;
              }

              /** We assign the new auto ponderator with the multiplier constant with criteria */
              activity.ponderator = newPonderator;
              /* } */
            }
          } else {
            activity.ponderator = 0;
          }
        } else {
          activity.ponderator = 0;
        }
      });

      // gantt.render()
    }
  }
};

export const defaultData = {
  data: [
    {
      duration: 24,
      id: 1600113954421,
      start_date: moment(new Date()).format('YYYY-MM-DD'),
      text: 'New Master Plan',
      constraint_type: 'asap',
      parent: 0,
      end_date: moment(new Date()).add(3, 'days').format('YYYY-MM-DD'),
      progress: '0.00',
      old_duration: 24,
      for_disable_milestone_duration: 24,
      hhWorkTime: 0,
      cost: 0,
      expected_progress: 100,
      should_be_showed: true,
      calendar_id: null,
      real_constraint_type: 'asap',
      custom_predecessor: '',
      ponderator: 0,
      correlative_id: 0,
      unique_correlative_id: 1,
      is_open_lightbox: false,
      type: 'project',
      should_correct_start_date: true,
      responsables: [],
      tags: [],
      activityModifications: [],
      description: ''
    },
    {
      duration: 8,
      id: 1600113954422,
      start_date: moment(new Date()).format('YYYY-MM-DD'),
      text: 'New Activity 1',
      parent: 1600113954421,
      constraint_type: 'asap',
      end_date: moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
      progress: 0,
      old_duration: 8,
      for_disable_milestone_duration: 8,
      hhWorkTime: 0,
      cost: 0,
      expected_progress: 100,
      should_be_showed: true,
      calendar_id: null,
      real_constraint_type: 'asap',
      custom_predecessor: '',
      freeSlack: 0,
      ponderator: 0,
      correlative_id: 1,
      unique_correlative_id: 2,
      is_open_lightbox: false,
      should_correct_start_date: true,
      is_milestone: false,
      responsables: [],
      tags: [],
      activityModifications: [],
      description: ''
    },
    {
      duration: 8,
      id: 1600113954423,
      start_date: moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
      text: 'New Activity 2',
      parent: 1600113954421,
      constraint_type: 'asap',
      end_date: moment(new Date()).add(2, 'days').format('YYYY-MM-DD'),
      progress: 0,
      old_duration: 8,
      for_disable_milestone_duration: 8,
      hhWorkTime: 0,
      cost: 0,
      expected_progress: 100,
      should_be_showed: true,
      calendar_id: null,
      real_constraint_type: 'asap',
      custom_predecessor: '',
      freeSlack: 0,
      ponderator: 0,
      correlative_id: 2,
      unique_correlative_id: 3,
      is_open_lightbox: false,
      should_correct_start_date: true,
      is_milestone: false,
      responsables: [],
      tags: [],
      activityModifications: [],
      description: ''
    },
    {
      duration: 8,
      id: 1600113954425,
      start_date: moment(new Date()).add(2, 'days').format('YYYY-MM-DD'),
      text: 'New Activity 3',
      parent: 1600113954421,
      constraint_type: 'asap',
      end_date: moment(new Date()).add(3, 'days').format('YYYY-MM-DD'),
      progress: 0,
      old_duration: 8,
      for_disable_milestone_duration: 8,
      hhWorkTime: 0,
      cost: 0,
      expected_progress: 100,
      should_be_showed: true,
      calendar_id: null,
      real_constraint_type: 'asap',
      custom_predecessor: '',
      freeSlack: 0,
      ponderator: 0,
      correlative_id: 3,
      unique_correlative_id: 4,
      is_open_lightbox: false,
      should_correct_start_date: true,
      is_milestone: false,
      responsables: [],
      tags: [],
      activityModifications: [],
      description: ''
    }
  ],
  links: []
};

/**
 * This function takes a screenshot of current calendars configuration, it includes
 * calendars, their shifts, calendar exceptions and their shifts as well, and other config.
 * @param {*} newBaselineVersion New baseline object which is going to belong this base calendars
 * @param {*} lastActiveBaselineVersion Ref from last active baseline
 * @returns Hash object which maps original calendar ID to new base calendar ID
 */
export const createBaseCalendarFromCalendar = async (
  newBaselineVersion,
  lastActiveBaselineVersion,
  calendars
) => {
  const hashTable = {};
  let copyOfCalendar = calendars.filter((cal) => typeof cal.id !== 'string');
  if (
    (newBaselineVersion.saveOption == 2 ||
      newBaselineVersion.saveOption == 3 ||
      newBaselineVersion.saveOption == 4) &&
    lastActiveBaselineVersion
  ) {
    const lastActiveBaseCalendar = calendars.find(
      (cal) => cal.sectorBaselineVersionId == lastActiveBaselineVersion.id
    );
    if (lastActiveBaseCalendar) {
      copyOfCalendar = [...copyOfCalendar, lastActiveBaseCalendar];
    }
  }

  const asyncMap = copyOfCalendar.map(async (calendar) => {
    /** We create a new base calendar object */
    const newBaseCalendar = {
      ...calendar,
      sectorBaselineVersionId: newBaselineVersion.id,
      is_default: calendar.is_default || false
    };
    const res = await baseCalendarService.create(newBaseCalendar);

    if (res) {
      /** We takes calendar shifts and clone it into a base working day (for baselines) */
      const asyncBaseWorkingDays = calendar.shifts.map(async (shift) => {
        /** We create a new base working day object */
        const newBaseWorkingday = {
          shift_string: shift.shift_string,
          correlative_id: shift.correlative_id,
          basecalendarId: res.id
        };
        await baseworkingdayService.create(newBaseWorkingday);
      });
      await Promise.all(asyncBaseWorkingDays);

      /** we map calendar ID to base calendar ID */
      hashTable[calendar.id] = res.id;

      /** Then async clone calendar exceptions for base calendar exceptions */
      calendar.exceptiondays.map(async (exception) => {
        const newBaseCalendarExceptionDay = {
          ...exception,
          baseCalendarId: res.id
        };
        newBaseCalendarExceptionDay.every_type = exception.every_type;
        const res2 = await BaseCalendarExceptionDaysService.create(
          newBaseCalendarExceptionDay
        );
        if (res2) {
          /** N workingdays integration */
          const asyncMap = exception.shifts.map(async (shift) => {
            const newBaseWorkingday = {
              shift_string: shift.shift_string,
              correlative_id: shift.correlative_id,
              basecalendarexceptiondayId: res2.id
            };
            await baseworkingdayService.create(newBaseWorkingday);
          });
          await Promise.all(asyncMap);
        }
      });
    }
  });
  await Promise.all(asyncMap);
  return hashTable;
};

/**
 * This function creates new baseline point from current data at gantt chart
 * it will change between options which actually can:
 * - Replace whole points by new data
 * - Keep original points for those activities which already has a point, and create new for those which does not, UPDATE: BUT PARENTS MODIFIED BY NEW ACTIVITIES WILL UPDATE BASELINE POINT
 * - Keep original points for those activities which has 100% progress, and create new for those which does not
 * @param {*} calendarToBaseCalendarHash Hash table which map calendar ID to base calendar ID
 * @param {*} newBaselineVersion new baseline version object
 * @param {*} gantt current gantt object
 * @param {*} sector current sector object
 * @param {*} parentsToDelete master task items to save baselines
 * @returns Returns a body to be used for creating all new baseline points for a baseline version
 */
export const getBodyForNewBaseline = (
  calendarToBaseCalendarHash,
  newBaselineVersion,
  gantt,
  sector,
  parentsToDelete = []
) => {
  const activities = gantt.serialize().data;
  const toAssignBaselineActivities = [];
  // If in future versions of new baseline creation, just addapt this object to keep those required values
  const colToHardClone = {
    hh_work: 'hhWorkTime',
    cost: 'cost'
  };

  /** All activities will be replaced with a follow up baseline point */
  if (newBaselineVersion.saveOption == 1) {
    activities.forEach((activity) => {
      if (activity.proplannerId) {
        cloneActivityToBasePoint(
          activity,
          toAssignBaselineActivities,
          calendarToBaseCalendarHash,
          sector
        );
      }
    });
    /** Activities with old baselinepoint active will keep it, if there is a parent that is involved on a new activity, and their dates has changed, will get a new point */
  } else if (newBaselineVersion.saveOption == 2) {
    activities.forEach((activity) => {
      const hasOldActivePoint = cloneLastBasePoint(
        activity,
        toAssignBaselineActivities,
        colToHardClone,
        calendarToBaseCalendarHash
      );
      if (!hasOldActivePoint) {
        cloneActivityToBasePoint(
          activity,
          toAssignBaselineActivities,
          calendarToBaseCalendarHash,
          sector
        );
      }
    });
    /** Completed activities which has an old active base point, will keep it */
  } else if (newBaselineVersion.saveOption == 3) {
    activities.forEach((activity) => {
      const hasOldActivePoint =
        activity.progress >= 99.99 &&
        cloneLastBasePoint(
          activity,
          toAssignBaselineActivities,
          colToHardClone,
          calendarToBaseCalendarHash
        );

      if (!hasOldActivePoint) {
        cloneActivityToBasePoint(
          activity,
          toAssignBaselineActivities,
          calendarToBaseCalendarHash,
          sector
        );
      }
    });
    /** This option happens when a activity is deleted, and that activity defines the parent activities dates (Start or end) */
  } else if (newBaselineVersion.saveOption == 4) {
    activities.forEach((activity) => {
      if (parentsToDelete.includes(activity.proplannerId)) {
        /** current */
        if (activity.proplannerId) {
          cloneActivityToBasePoint(
            activity,
            toAssignBaselineActivities,
            calendarToBaseCalendarHash,
            sector
          );
        }
      } else {
        /** clone */
        const hasOldActivePoint = cloneLastBasePoint(
          activity,
          toAssignBaselineActivities,
          colToHardClone,
          calendarToBaseCalendarHash
        );
        if (!hasOldActivePoint) {
          cloneActivityToBasePoint(
            activity,
            toAssignBaselineActivities,
            calendarToBaseCalendarHash,
            sector
          );
        }
      }
    });
  }
  const bodyToSaveBaseline = {
    version_id: newBaselineVersion.id,
    sector_id: newBaselineVersion.sectorId,
    activities: toAssignBaselineActivities,
    save_option: newBaselineVersion.saveOption
  };

  return bodyToSaveBaseline;
};

/**
 * This function clone all activity data, into a baseline point
 * @param {*} originalActivity Original activity which attributes are going to be copied to a basleine point
 * @param {*} arrayToPush Final array that will be used for massive baseline points save
 * @param {*} calendarToBaseCalendarHash Hash table which maps each calendar ID to an base calendar ID
 */
const cloneActivityToBasePoint = (
  originalActivity,
  arrayToPush,
  calendarToBaseCalendarHash,
  sector
) => {
  /** Hour integration */
  arrayToPush.push({
    ...originalActivity,
    hh_work: originalActivity.hhWorkTime,
    cost: originalActivity.cost,
    duration: transformHourToDays(originalActivity.duration),
    baseCalendarId: calendarToBaseCalendarHash[originalActivity.calendar_id],
    sumOfDurationRecursively: originalActivity.sumOfDurationRecursively,
    hoursPerDay: sector.hoursPerDay,
    hoursPerWeek: sector.hoursPerWeek
  });
};

/**
 * This function check if some activity has an active baseline point, and clones it into a new object without id
 * @param {*} originalActivity Original activity which is going to have a new baseline point
 * @param {*} arrayToPush Final array to save all baseline points
 * @param {*} columnsToHardCode Map to clone current values from activity to base point. format { keyFromPoint: keyFromActivty }
 * @param {*} hashTable Hash table which maps each calendar ID to an base calendar ID
 * @returns True if the activity has an baseline active point, and if it is added to the given array
 */
const cloneLastBasePoint = (
  originalActivity,
  arrayToPush,
  columnsToHardCode = {},
  hashTable
) => {
  /** Get active baseline point */
  if (!originalActivity.baseline_points) return false;
  const activeBaselinePoint = originalActivity.baseline_points.find((base) => {
    if (base.sectorbaselineversion) {
      if (base.sectorbaselineversion.active) {
        return true;
      }
    }
  });
  /** If exist we will copy this */
  if (activeBaselinePoint) {
    /** Hour integration */
    const newBasePoint = {
      ...activeBaselinePoint,
      clonedFromOlderBasePoint: true
    };

    Object.keys(columnsToHardCode).map((key) => {
      const originalValueAttribute = columnsToHardCode[key];
      const originalValue = originalActivity[originalValueAttribute];
      newBasePoint[key] = originalValue;
    });
    delete newBasePoint.id;
    delete newBasePoint.sectorbaselineversion;
    delete newBasePoint.sectorbaselineversionId;
    newBasePoint.baseCalendarId = hashTable[originalActivity.calendar_id];
    if (newBasePoint.hh_work === null) {
      newBasePoint.hh_work = 0;
    }
    arrayToPush.push(newBasePoint);
    return true;
  }
  return false;
};

/**
 * This function gets a hidden string to display in the pdf, for a specified column
 * @param {*} dataString column data
 * @param {*} column column name
 * @param {*} offset offset in pixels (activity name case)
 * @returns html of string hidden for pdf
 */
export const getStringHidden = (dataString, column, offset = 0) => {
  let htmlRet = dataString;
  const gantt = window.to_use_react_gantt;
  if (!gantt) return '';
  const findColumn = gantt.config.columns.find((el) => el.name === column);
  if (findColumn) {
    const w1 = parseInt(findColumn.width) + offset;
    htmlRet = `<span class="string-h" style="width: ${w1}px!important">${dataString}</span>`;
  }
  return htmlRet;
};

const cleanShiftsWithoutHours = (calendars) => {
  if (!calendars) return;
  if (!calendars.length) return;

  calendars.forEach((calendar) => {
    const filteredShifts = calendar.shifts?.filter((shift) => {
      let hasValidShifts = shift.shift_string
        .split(',')
        .some((shiftValue) => !/^false(-false)*$/.test(shiftValue));
      return hasValidShifts;
    });
    calendar.shifts = filteredShifts;
    return calendar;
  });
};

/**
 * This function makes a get to obtain the calendars of a sector
 * @param {*} sectorId current sector
 * @param {*} setCalendars function to save the state variable calendars
 * @returns
 */
export const refreshCalendars = async (sectorId, setCalendars) => {
  const sectorRes = await sectorService.showCalendar(sectorId);
  const sectorResBaseCalendar = await sectorService.showBaseCalendar(sectorId);
  if (!sectorRes.sector) return;
  const { calendars } = sectorRes.sector;
  cleanShiftsWithoutHours(calendars);
  const baseCalendarsArray = [];

  sectorResBaseCalendar.basecalendars.map((baseCalendar) => {
    const newBase = {
      ...baseCalendar,
      exceptiondays: baseCalendar.baseexceptiondays,
      id: baseCalendar.id + '-base',
      baseDefault: baseCalendar.is_default
    };
    delete newBase.is_default;

    baseCalendarsArray.push(newBase);
  });

  setCalendars([...calendars, ...baseCalendarsArray]);
  return sectorRes;
};

/**
 * This function obtains the minimum and maximum hours of a shift.
 * @param {*} calendars calendars to calculate dates
 * @param {*} customHour state variable to save the response
 * @param {*} setCustomHour function to save state variable
 */
export const setCustomHourFn = (calendars, customHour, setCustomHour) => {
  if (Object.keys(calendars).length !== 0) {
    const calendarDefault = calendars && calendars.find((el) => el.is_default);
    if (calendarDefault) {
      const shifts = calendarDefault.shifts;
      const shiftOrdered = shifts.sort(dynamicSort('correlative_id', true));

      /** get shifts */
      const firstShift = shiftOrdered[0].shift_string;
      const lastShift = shiftOrdered[shiftOrdered.length - 1].shift_string;

      /** calculate ini */
      const shiftArr = firstShift.split('-');
      const firstHourArr = shiftArr[0].split(',');

      /** calculate end */
      const shiftArrEnd = lastShift.split('-');
      const lastHourArr = shiftArrEnd[1].split(',');

      /** calculate min hour of the last shift */
      let lastHourMin = 99;
      firstHourArr.map((el) => {
        if (parseInt(el) < parseInt(lastHourMin)) {
          lastHourMin = el;
        }
      });

      /** calculate max hour of the last shift */
      let lastHourMax = 0;
      lastHourArr.map((el) => {
        if (parseInt(el) > parseInt(lastHourMax)) {
          lastHourMax = el;
        }
      });

      if (lastHourMin && lastHourMax) {
        setCustomHour({
          ...customHour,
          startHour: lastHourMin,
          endHour: lastHourMax
        });
      }
    }
  }
};

/**
 * This function removes links that are part of a loop of circular links
 * @param {*} gantt Gantt object
 * @param {*} groups Array of elements with tasks/links that are part of the circular loop
 */
export const removeActionsAdded = (gantt, groups = null) => {
  const allLinks = gantt.getLinks();
  if (gantt.allLinksBefore?.length) {
    const linksToDelete = allLinks.filter(
      (e) => !gantt.allLinksBefore.includes(e)
    );
    eachCircularLinks(gantt, groups, linksToDelete);
  }
};

/**
 * This function detects if there are groups of circular loops or only a single loop is detected
 * @param {*} gantt Gantt object
 * @param {*} groups Group of links and tasks that belong to the loop
 * @param {*} linksToDelete Group of links and tasks that belong to the loop
 */
const eachCircularLinks = (gantt, groups = null, linksToDelete) => {
  if (Array.isArray(groups)) {
    groups &&
      groups.forEach((group) => {
        deleteCircularLink(gantt, group, linksToDelete);
      });
  } else if (Object.keys(groups).length !== 0) {
    deleteCircularLink(gantt, groups, linksToDelete);
  }
};

/**
 * This function delete the links that create the circular loop and return to the previous state, before creating the circular loop
 * @param {*} gantt Gantt object
 * @param {*} groups Group of links and tasks that belong to the loop
 * @param {*} linksToDelete Group of links and tasks that belong to the loop
 */
const deleteCircularLink = (gantt, group, linksToDelete) => {
  if (Array.isArray(linksToDelete)) {
    group.links.forEach((link) => {
      linksToDelete.forEach((link) => {
        if (group?.links.includes(link.id)) {
          /** delete link */
          if (gantt.isLinkExists(link.id)) {
            gantt.deleteLink(link.id);
          }
        }
      });
    });
  }
};

/**
 * This function checks the role of the logged in user, to determine if he can use the bulk action bar in the PM
 * @returns true or false according if the user is enabled for use bulk actions bar
 */
export const enableForMassiveSelect = () => {
  const sessionTokenData = getSessionTokenData();
  return ['superadmin', 'admin', 'planner', 'projectleader'].includes(
    sessionTokenData.role
  );
};

/**
 * This constant is used to compare and exclude ids that are greater than 7 digits in length
 */
export const MAX_ACTIVITIES = 9999999;

/**
 * This function returns the next UID, based on the number of initial activities
 * @param {Array} data Array of existing activities to upload to the gantt
 * @returns {number} The next uid to assign to the next activity
 */
export const getNextUIDAtLoad = (data) => {
  /** exclude activities with too many characters */
  const ids = data
    .map((a) => a.unique_correlative_id)
    .filter((el) => Number(el) <= MAX_ACTIVITIES);

  /** get max id */
  const highestId = ids.length ? Math.max(...ids) : 0;
  return Number(highestId);
};

/**
 * A cache to store the maximum UID per sector to avoid recalculating it multiple times.
 * Keys are sector IDs, values are the maximum UID found.
 */
const maxUIDCache = {};

/**
 * This function gives us the UID to assign to the New Activity, according to the current sector
 * * @param {*} sector Sector ID to find the lastUniqueCorrelativeId value
 * * @param {*} lastUniqueCorrelativeIds Lasunique correlative id from redux gantt state
 * * @param {*} gantt Gantt Object
 * @returns id to assign to activities
 */
export const getNextUID = (
  sector,
  lastUniqueCorrelativeIds,
  gantt,
  allActivities
) => {
  const findLastUnique = lastUniqueCorrelativeIds.find(
    (el) => parseInt(el.sectorId) === parseInt(sector)
  );

  if (!findLastUnique) {
    return 1;
  }

  if (maxUIDCache[sector] === undefined) {
    const activities = allActivities || gantt.getTaskByTime();
    maxUIDCache[sector] = getNextUIDAtLoad(activities);
  }

  let retVal = Number(findLastUnique.lastUniqueCorrelativeId) + 1;

  if (retVal <= maxUIDCache[sector]) {
    retVal = maxUIDCache[sector] + 1;
  }

  if (retVal > maxUIDCache[sector]) {
    maxUIDCache[sector] = retVal;
  }

  return retVal;
};

/**
 * Convert a date string to its ISO string representation.
 * If the date is not valid, returns null.
 *
 * @param {string} date - The date string.
 * @returns {string|null} - The ISO string representation of the date or null.
 */
const toISODateString = (date) => {
  const newDate = new Date(date);
  return isNaN(newDate.getTime()) ? null : newDate.toISOString();
};

/**
 * Processes an object of submittals to transform and sanitize its properties.
 * Filters out submittals with a null activityId.
 * Converts certain time-related fields to numbers and date strings to ISO strings.
 *
 * @param {Object} submittals - The submittals object.
 * @returns {Array} - An array of processed submittal objects.
 */
const processSubmittals = (submittals) =>
  Object.values(submittals)
    .filter(({ activityId }) => activityId !== null)
    .map(
      ({
        leadTime,
        designTeamReviewTime,
        internalReviewTime,
        requiredOnSiteDate,
        ...rest
      }) => ({
        ...rest,
        leadTime: Number(leadTime) || 0,
        designTeamReviewTime: Number(designTeamReviewTime) || 0,
        internalReviewTime: Number(internalReviewTime) || 0,
        requiredOnSiteDate: toISODateString(requiredOnSiteDate)
      })
    );

/**
 * Asynchronously updates submittals by invoking the `procoreService.updateSubmittals` method.
 * Captures exceptions and logs them for diagnostic purposes.
 *
 * @async
 * @param {Array} submittals - An array of submittal objects to update.
 * @param {Object} company - Company object containing at least an 'id' field.
 * @param {Object} project - Project object containing at least an 'id' field.
 * @param {Object} sector - Sector object containing at least an 'id' field.
 * @returns {Promise<void>} - Promise representing the completion of the update operation.
 * @throws Will capture and log the exception using Sentry and a custom logging utility.
 */
export const updateSubmittalsHelper = async (company, project, sector) => {
  const currentSubmittalState = store.getState().submittalState;
  if (
    !currentSubmittalState ||
    Object.keys(currentSubmittalState).length === 0
  ) {
    console.warn('submittalState is empty. Skipping submittal update.');
    return;
  }
  const submittals = processSubmittals(currentSubmittalState.submittals);
  const companyId = company?.id;
  const sectorId = sector?.id;
  const projectId = project?.id;

  if (!companyId || !sectorId || !projectId) {
    console.error('Required ID fields are missing on submittal update');
    return;
  }

  try {
    await procoreService.updateSubmittals({
      companyId,
      sectorId,
      projectId,
      submittals
    });
  } catch (error) {
    Sentry.captureException(error);
    log('error_update_submittals', {
      company: companyId,
      project: projectId,
      sector: sectorId,
      error
    });
  }
};

/**
 * Updates existing submittals with new data.
 *
 * @param {Object} existingSubmittals - An object representing existing submittals, where each key is a submittal ID and each value is the submittal data.
 * @param {Array} updates - An array of updated submittal objects.
 *
 * @returns {Object} An updated object containing the existing submittals merged with the updates.
 * @example
 * const existing = { "1": { submittalId: 1, name: "oldName" } };
 * const updates = [{ submittalId: 1, name: "newName" }];
 * updateExistingSubmittals(existing, updates);
 * // Returns: { "1": { submittalId: 1, name: "newName" } }
 */
export const updateExistingSubmittals = (existingSubmittals, updates) => {
  const updatesBySubmittalId = Object.fromEntries(
    updates.map((s) => [s.submittalId.toString(), s])
  );
  const updatedSubmittals = { ...existingSubmittals };

  Object.keys(updatedSubmittals).forEach((key) => {
    const submittal = updatedSubmittals[key];
    if (updatesBySubmittalId[submittal.submittalId]) {
      updatedSubmittals[key] = updatesBySubmittalId[submittal.submittalId];
    }
  });

  return updatedSubmittals;
};

export const getAccumulatedGanttDuration = (gantt) => {
  const activitiesWithoutChildren = gantt.getTaskBy(
    ({ id }) => !gantt.hasChild(id)
  );

  const accumulatedDuration = activitiesWithoutChildren.reduce(
    (accumulated, { duration }) => accumulated + duration,
    ZERO
  );

  return accumulatedDuration;
};

export const baselineOptions = {
  1: 'Entire Project',
  2: 'New Activities',
  3: 'Incompletes Activities'
};
